import { GlobalHoverService } from 'o365-vue-services';
import ObjectHover from 'assets.vue.components.objects.ObjectHover.vue';

export default {
    mounted(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        service.bindElement(el, {
            component: ObjectHover,
            props: { 
                objectId: binding.value,
            },
        })
    },
    updated(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        const props = service.getPropsFromEl(el)
        if (props && props.objectId !== binding.value) {
            props.objectId = binding.value;
        }
    },
    unmounted(el: HTMLElement, _binding: any) {
        const service = GlobalHoverService.getService();
        service.unbindElement(el);
    }
};